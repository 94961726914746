body {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(0, 0, 0, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

label {
  color: #FFF !important;
}

#thorin2 {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  will-change: filter;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
  color: white;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@keyframes background-glow {
  0% { filter: drop-shadow(0 0 8em #61dafbaa); }
  25% { filter: drop-shadow(0 0 3em #61dafbaa); }
  50% { filter: drop-shadow(0 0 8em #61dafbaa); }
  75% { filter: drop-shadow(0 0 3em #61dafbaa); }
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #00000f;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
