#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 15em;
  padding: 1.5em;
  will-change: filter, animation;
  transition: filter 1s;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #ff0000);
}
.logo.react:hover {
  filter: drop-shadow(0 0 8em #ff0000);
  animation: background-glow 8s infinite;
  animation-delay: 1s;
  will-change: filter, animation;
  transition: filter 1s;
}

.input-container input[type="text"] {
  width: 100%; /* Menyesuaikan lebar dengan lebar parent container */
  font-size: 1.2em;
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

/* App.css */

.App {
  text-align: center;
  background-color: #222;
  color: #fff;
}

.title {
  color: #ff0000; /* Red */
}

.subtitle {
  color: #ff0000; /* Red */
}

.input-container {
  margin-top: 20px;
}

input[type="text"] {
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}

.coins-container {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #333;
  color: #fff;
}

.total-coins {
  font-weight: bold;
  color: #ff0000; /* Red */
}

.total-coins-value {
  color: #ff0000; /* Red */
}
.logo {
  border-radius: 50%;
}
.error-message {
  color: red;
  margin-top: 10px;
}
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.total-reward-label {
  font-size: 2em;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 20px;

}
.total-vesting-label {
  font-size: 2em;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 20px;
  color: green;

}

.total-coins-value {
  font-size: 2em;
  font-weight: bold;
}


.input-border {
  border-top: 35px solid #333;
  padding-top: 10px; /* Opsi untuk memberikan ruang antara garis dan input */
}
.error-message {
  font-size: 1.7em;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;

}